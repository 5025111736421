<script context="module" lang="ts">
  interface Testimonial {
    name: string;
    body: string;
  }
</script>

<script lang="ts">
  import emblaCarouselSvelte from "embla-carousel-svelte";
  import autoPlay from "embla-carousel-autoplay";

  export let testimonials: Testimonial[] = [];

  const plugins = [autoPlay()];

  function onInit(event) {
    const embla = event.detail;
    embla.on("select", () => {});
  }
</script>

{#if testimonials.length}
  <div class="m-auto max-w-16 border-dotted border-t-4 border-p400 mb-6" />
  <div
    use:emblaCarouselSvelte={{ options: {}, plugins }}
    on:emblaInit={onInit}
    class="embla"
    use:emblaCarouselSvelte
  >
    <div class="embla__container">
      {#each testimonials as { name, body }}
        <div class="embla__slide">
          <p
            class="text-center text-white italic text-body-base font-thin mb-4"
          >
            “{body}”
          </p>
          <p class="text-center text-n80 font-bolder">– {name}</p>
        </div>
      {/each}
    </div>
  </div>
{/if}

<style>
  .embla {
    overflow: hidden;
    max-width: 48rem;
    margin: auto;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 100%;
  }
  .embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
  }
  .embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
  }
</style>
